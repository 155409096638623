import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function Consulting() {
  return (
    <div className="max-w-4xl mx-auto p-6 text-white">
      <h1 className="text-3xl font-bold mb-4">🔊 CHURCH TECH SUPPORT & CONSULTING 🔧</h1>
      <p className="text-lg font-semibold">🚨 90% of churches struggle with underperforming AVL & tech! 🚨</p>
      <p>Why? Because the people managing it often lack the right knowledge.</p>

      <h2 className="text-2xl font-bold mt-6">WE PROVIDE THE SOLUTION ✅</h2>
      <p>We equip your volunteers, staff & pastors to effectively manage & optimize church technology!</p>

      <h2 className="text-2xl font-bold mt-6">What We Offer 🎯</h2>
      <ul className="list-disc list-inside space-y-2">
        <li><strong>✅ Tech & AVL Training</strong> – Volunteers & staff learn to run systems confidently.</li>
        <li><strong>✅ System Optimization</strong> – We analyze & improve your current setup.</li>
        <li><strong>✅ Equipment Procurement</strong> – Cost-effective solutions tailored to your church.</li>
        <li><strong>✅ Installation Assistance</strong> – From guidance to full turn-key solutions.</li>
        <li><strong>✅ Ongoing Support</strong> – Troubleshooting & expert advice when you need it.</li>
      </ul>

      <h2 className="text-2xl font-bold mt-6">CHOOSE YOUR SUPPORT PLAN 🏆</h2>
      <div className="space-y-4">
        <div className="p-4 bg-white shadow-md rounded-lg text-black">
          <h3 className="text-xl font-bold">⭐ Tier 3 – R6000/month (Full Support)</h3>
          <ul className="list-disc list-inside">
            <li>📌 Project Planning & Procurement</li>
            <li>📌 4x 30-min Zoom consultations + 1x 1-hour Mentorship</li>
            <li>📌 <strong>Phone Support calls included</strong> 📞</li>
            <li>📌 Reduced add-on costs (-50%)</li>
            <li>📌 2 free workshop seats</li>
          </ul>
        </div>
        
        <div className="p-4 bg-white shadow-md rounded-lg text-black">
          <h3 className="text-xl font-bold">🔥 Tier 2 – R3500/month (Balanced Support)</h3>
          <ul className="list-disc list-inside">
            <li>📌 Project Planning</li>
            <li>📌 2x 30-min Zoom consultations + 1x 1-hour Mentorship</li>
            <li>📌 <strong>Phone Support calls included</strong> 📞</li>
            <li>📌 Reduced add-on costs (-30%)</li>
            <li>📌 1 free workshop seat</li>
          </ul>
        </div>
        
        <div className="p-4 bg-white shadow-md rounded-lg text-black">
          <h3 className="text-xl font-bold">💡 Tier 1 – R1800/month (Foundational Support)</h3>
          <ul className="list-disc list-inside">
            <li>📌 Project Planning & 1-hour Mentorship (combined)</li>
            <li>📌 1x 30-min Zoom consultation + 2x 10-min Phone support calls</li>
            <li>📌 Reduced add-on costs (-15%)</li>
            <li>📌 Discounted workshops</li>
          </ul>
        </div>
      </div>
      
      <h2 className="text-2xl font-bold mt-6">What is an additional cost? 💰</h2>
      <ul className="list-disc list-inside space-y-2">
        <li>🔹 Extra Zoom consultations</li>
        <li>🔹 Additional Workshops</li>
        <li>🔹 Extra Training Sessions</li>
        <li>🔹 Equipment Procurement Fees</li>
        <li>🔹 Call-out Services</li>
      </ul>
      
      <p className="mt-6 font-semibold">🚀 <strong>ANY SERVICE AVAILABLE AS A STANDALONE OPTION!</strong></p>
      <div className="mt-6 text-lg font-semibold cursor-pointer hover:text-customGreen">
                          <Link to="/hello">
                          📩 Contact us today!
                          </Link>
                          </div>
      
    </div>
  );
}

export default Consulting;
