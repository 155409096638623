import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { Dialog } from "@headlessui/react";
import logo from "../Next Logo only.png";

function Hello() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    church: "",
    position: "",
    message: "",
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  const questions = [
    { id: "name", label: "What is your name and surname?", type: "text" },
    { id: "email", label: "What is your email?", type: "email" },
    { id: "phone", label: "What is your phone number?", type: "tel" },
    { id: "church", label: "Church name?", type: "text" },
    { id: "position", label: "What is your position?", type: "text" },
    { id: "message", label: "How may we support you??", type: "textarea" },
  ];

  const domain = "https://nextis.co.za";
  //const domain = "http://localhost:5001";

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleNext = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    try {
      await axios.post(`${domain}/api/hello`, formData);
      setSubmitted(true);
    } catch (err) {
      setError("Failed to submit. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadVCard = () => {
    window.location.href = `${domain}/api/contact-card`;
  };

  const handleHome = () => {
    window.location.href = "https://nextis.co.za";
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="dialog-overlay">
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="bg-customBlack rounded-lg max-w-sm sm:max-w-lg lg:max-w-xl p-6 shadow-lg border border-white border-opacity-50">
          <div className="flex flex-col items-center pb-4">
                    <img className="h-14 w-auto mb-4" src={logo} alt="Logo" />
                    <div className="flex flex-shrink-0 items-center pb-4">
                    <span className="text-white text-m items-center opacity-80 ml-3 font-bold .agdasima ">Pioneering Digital Kingdom</span>
                      </div>
                    </div>
            {!submitted ? (
              <motion.div
                key={currentStep}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
              >
                <label className="block text-lg font-medium mb-2 text-white">
                  {currentStep === 4 && formData.church
                    ? `What is your position at ${formData.church}?`
                    : questions[currentStep].label}
                </label>
                {questions[currentStep].type === "textarea" ? (
                  <textarea
                    id={questions[currentStep].id}
                    value={formData[questions[currentStep].id]}
                    onChange={handleChange}
                    className="w-full rounded-lg py-2.5 px-4 text-white bg-transparent border border-white placeholder:text-gray-400 focus:outline-none"
                    rows="3"
                  />
                ) : (
                  <input
                    type={questions[currentStep].type}
                    id={questions[currentStep].id}
                    value={formData[questions[currentStep].id]}
                    onChange={handleChange}
                    className="w-full rounded-lg py-2.5 px-4 text-white bg-transparent border border-white placeholder:text-gray-400 focus:outline-none"
                  />
                )}
                <button
                  onClick={handleNext}
                  className="mt-4 w-full bg-customGreen text-black py-2.5 rounded-lg font-semibold text-lg shadow-md"
                  disabled={loading}
                >
                  {currentStep < questions.length - 1 ? "Next" : loading ? "Submitting..." : "Submit"}
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </motion.div>
            ) : (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
                <h2 className="text-xl font-bold mb-4 text-white text-center">Thank you for connecting!</h2>
                <p className="text-white"><strong>Name:</strong> {formData.name}</p>
                <p className="text-white"><strong>Email:</strong> {formData.email}</p>
                <p className="text-white"><strong>Phone:</strong> {formData.phone}</p>
                <p className="text-white"><strong>Message:</strong> {formData.message}</p>
              </motion.div>
            )}
            <button onClick={() => setIsOpen(false)} className="mt-4 w-full bg-gray-600 text-white py-2.5 rounded-lg">Close</button>
          </Dialog.Panel>
        </div>
      </Dialog>

      {!isOpen && (
        <div className="container px-3 md:px-8 py-10 mx-auto">
          <div className="bg-customBlack rounded-lg max-w-sm sm:max-w-lg lg:max-w-xl mx-auto p-6 shadow-lg border border-white border-opacity-50">
          <div className="flex flex-shrink-0 items-center pb-4">
                    <img className="h-14 w-auto mb-4" src={logo} alt="Logo" />
                    <div className="flex flex-shrink-0 items-center pb-4">
                    <span className="text-white text-m items-center opacity-80 ml-3 font-bold .agdasima ">Pioneering Digital Kingdom</span>
                      </div>
                    </div>
            <h2 className="text-xl font-bold mb-4 text-white text-center">Next is Contact Details</h2>
            <p className="text-white"><strong>Name:</strong> Arno Pretorius</p>
            <p className="text-white"><strong>Company:</strong> Next Is</p>
            <p className="text-white"><strong>Email:</strong> arno@nextis.co.za</p>
            <p className="text-white"><strong>Phone:</strong> +27 61 036 2881</p>
            <p className="text-white"><strong>Website:</strong> <a href="https://nextis.co.za" className="text-blue-400 underline">nextis.co.za</a></p>
          </div>
          <button
            onClick={handleDownloadVCard}
            className="mt-4 w-full bg-customGreen text-black py-2.5 rounded-lg font-semibold text-lg shadow-md"
          >
            Download Contact Card
          </button>
          <button onClick={() => setIsOpen(true)} className="mt-4 w-full bg-gray-600 text-white py-2.5 rounded-lg">Fill in connect card</button>
          <button onClick={handleHome} className="mt-4 w-full bg-gray-600 text-white py-2.5 rounded-lg">Home</button>
        </div>
      )}
    </>
  );
}

export default Hello;
